<template>
  <div class="introduction">
    <div>
      <img src="@/assets/icon/icon_host18.png" />
      <span>>{{ AllData.dbName }}简介</span>
    </div>
    <div class="content">
      <div class="cont_header">{{ AllData.dbName }}</div>
      <div class="cont_c">
        <div class="sj tg">数据提供商:{{ AllData.dbProvider }}</div>
        <div class="sj">
          <span
            >是否含有全文:<span class="dk">{{ AllData.isFullText==1?'是':'否' }}</span></span
          >
          <span
            >数据库上传全文:<span class="dk">{{
              acquireTime(AllData.uploadTime)
            }}</span></span
          >
          <span
            >资源量:<span class="dk">{{ AllData.resourceNum }}</span></span
          >
        </div>
        <div class="sj">
          <span>
            数据库付费方式:<span class="lb">{{ AllData.chargeTypeTitlt }} </span>
          </span>
          <span
            >单个资源所需知识币<span class="lb">{{ AllData.onceCharge }}</span
            >个
          </span>
        </div>
        <p>{{ AllData.introduction }}</p>
        <div v-if="payinfo">
          <span
            style="font-weight: 700;
    margin-bottom: 5px;"
            >付费详情说明:</span
          >
          <div>
            <span
              >付费详情说明：
              单次付费：单个数据库单条数据下载原全文所需费用（下载过的原文不再重复付费。）
              包月：单个数据库，一个月内可以无限制下载</span
            >
          </div>
        </div>
        <el-button type="primary" @click="chack()">查看数据库</el-button>
      </div>
    </div>
    <el-dialog
      title="付费方式"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-radio v-model="radio" label="1" @change="radiof()">单次付费</el-radio>
      <el-radio v-model="radio" label="2" @change="radiof()">包月</el-radio>
      <div style="    margin: 10% 10% 0 22%;">
        {{ infoma }}需要支付{{ money }}个知识币
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="searchData">确认支付</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { INSERT, QUERY, DELETE, UPDATE, QUERYED } from "@/services/dao.js";

import {
  updatedownload,
  formatZero,
  getTimeFormat,
} from "@/js/common/Resoures";
export default {
  data() {
    return {
      id: "",
      AllData: {},
      payinfo: false,
      dialogVisible: false,
      radio: "1",
      infoma: "单次付费",
      money: "0",
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.init();
  },
  methods: {
    //获取数据
    async init() {
      let info = await QUERYED(
        "post",
        "",
        'dbInformation( where: {id: {_eq: "' +
          this.id +
          '"}},offset: ' +
          0 +
          ") {id dbName dbProvider chargeType resourceNum uploadTime  monthlyCharge onceCharge isFullText  introduction}"
      );
      this.AllData = info.data.dbInformation[0];
      this.money = this.AllData.onceCharge;
      switch (this.AllData.chargeType) {
        case 0:
          this.AllData.chargeTypeTitlt = "免费 ";
          this.payinfo = false;
          break;
        case 1:
          this.AllData.chargeTypeTitlt = "按月收费 ";
          this.payinfo = true;
          break;
        case 2:
          this.AllData.chargeTypeTitlt = "一次性收费 ";
          this.payinfo = true;
          break;
        case 3:
          this.AllData.chargeTypeTitlt = "即可一次性收费，也可按月收费 ";
          this.payinfo = true;
          break;
      }
      switch (this.AllData.isFullText) {
        case true:
          this.AllData.isFullText = "是 ";
          break;
        case false:
          this.AllData.isFullText = "否 ";
          break;
      }
    },


    //点击查看时
    async chack() {
      let {chargeType} = this.AllData
      //如果是免费的，直接查看
      if(chargeType==0){
        this.$router.push({
          path: "/payDoload",
          query: {
            id: this.id,
          },
        });
      }
      let info = await QUERYED(
        "post",
        "",
        'payInformation( where: {payName: {_eq: "' +
          this.$store.state.userinfo.id +
          '"},resoureceId : {_eq: "' +
          this.id +
          '"}},offset: ' +
          0 +
          ") {id,payType,endTime}"
      );
      let dataObj = info.data.payInformation[0]
      if(!dataObj)this.dialogVisible = true;
      else{
        let time = new Date()*1
        let endTime = dataObj.endTime 
        if(endTime>time){
          this.$router.push({
            path: "/payDoload",
            query: {
              id: this.id,
            },
          });
        }else{
          this.dialogVisible = true;
        }
      }
    },


    //点击支付
    async searchData() {
      let getPayInformation = await QUERYED(
        "post",
        "",
        'payInformation( where: {payName: {_eq: "' +
          this.$store.state.userinfo.id +
          '"},resoureceId : {_eq: "' +
          this.id +
          '"}},offset: ' +
          0 +
          ") {id,payType,endTime}"
      );
      let obj = getPayInformation.data.payInformation[0]
      let tableId = ''
      if(!obj){
        let operationSql = await INSERT(
          "post",
          "",
          'insert_payInformation(objects:{payType:"'+
            (this.infoma == "单次付费"?1:0)
          +'",dbName:"'+
            this.AllData.dbName
          +'",payName:"'+
            this.$store.state.userinfo.id
          +'",resoureceId:'+
            this.id
          +',expirationTime:"'+
            new Date()
          +'",endTime:'+
            (this.infoma == "单次付费"?new Date()*1+86400000:new Date()*1+2592000000)
          +'}) {affected_rows returning {id}}'
        )
        tableId = operationSql.data.insert_payInformation.returning[0].id
      }else{
        let operationSql = await UPDATE(
          "post",
          "",
          'update_payInformation(where:{id:{_eq:'+
            obj.id
          +'}},_set:{payType:"'+
            (this.infoma == "单次付费"?1:0)
          +'",expirationTime:"'+
            new Date()
          +'",endTime:'+
            (this.infoma == "单次付费"?new Date()*1+86400000:new Date()*1+2592000000)
          +'}) {affected_rows returning {id}}'
        )
        tableId = operationSql.data.update_payInformation.returning[0].id
      }

      let orderNum = "";
      let num = String(formatZero(this.$store.state.userinfo.id, 7));
      let orderlist = await QUERYED(
        "post",
        "",
        "ALLBill(where: {userid: {_eq: " +
          this.$store.state.userinfo.id +
        "}}) { time  orderNum   }"
      );
      if (orderlist.data.ALLBill.length < 1) {
        orderNum = getTimeFormat(new Date()) + num + "001";
      } else {
        for (let i = 0; i < orderlist.data.ALLBill.length; i++) {
          if (
            getTimeFormat(new Date()) ==
            getTimeFormat(orderlist.data.ALLBill[i].time)
          ) {
            let sic = orderlist.data.ALLBill[0].orderNum.substr(-3) * 1 + 1;
            orderNum =
              getTimeFormat(new Date()) + num + String(formatZero(sic, 3));
            break;
          } else {
            orderNum = getTimeFormat(new Date()) + num + "001";
          }
        }
      }

      //购买者支出订单
      let info = await INSERT(
        "post",
        "",
        'insert_bill(objects: {type: 5, state: 0, time: "' +
        new Date() +
        '", userid: ' +
        this.$store.state.userinfo.id +
        ', orderNum: "' +
        orderNum +
        '", orderid: ' +
        tableId + //数据库id
        ', Price: "' +
        this.money +
        '", evaluate: "",classification:1}) {affected_rows returning {id}}'
      );
      if(info.success){
        this.$message({
          message: "包月成功",
          type: "success",
          duration: "1000",
        });
        this.dialogVisible = false;
        this.$router.push({
          path: "/payDoload",
          query: {
            id: this.id,
          },
        });
      }else{
        await DELETE(
          "post","",
          'delete_payInformation(where: {id:{_eq:'+tableId+'}}) {affected_rows}'
        )
        this.$message({
          message: "付费失败",
          type: "warning",
          duration: "1000",
        });
        this.dialogVisible = false;
      }
    },
    
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },

    radiof() {
      switch (this.radio) {
        case "1":
          this.infoma = "单次付费";
          this.money = this.AllData.onceCharge;
          break;
        case "2":
          this.infoma = "包月";
          this.money = this.AllData.monthlyCharge;
          break;
      }
    },

    //获得时间
    acquireTime(time){
      let date = new Date(time)
      let Y = date.getFullYear()
      let M = date.getMonth()+1
      let D = date.getDate()
      // let h = date.getHours()
      // let m = date.getMinutes()
      // let s = date.getSeconds()
      return Y+'-'+M+'-'+D   //+' '+h+':'+m+":"+s
    }

  },
};
</script>
<style scoped>
.content {
  background-color: #ffffff;
}

.introduction {
  margin: 10px 100px 10px 130px;
}
.cont_header {
  text-align: center;
  line-height: 100px;
  font-size: 20px;
  border-bottom: 1px solid #e5e5e5;
}
.cont_c {
  padding: 20px 100px 20px 100px;
}
.sj {
  text-align: center;
  margin-bottom: 15px;
}
.sj span {
  margin-right: 30px;
}
.sj span .dk {
  color: #0084ff;
  margin-left: 5px;
}
.sj span .lb {
  color: #ca0400;
  margin-left: 5px;
}
.cont_c p {
  margin: 50px 0 50px 0px;
}
.cont_c button {
  margin-left: 40%;
  /* margin: 10px; */
  margin-top: 20px;
}
.tg {
  font-weight: 600;
  font-size: 14px;
}
.el-radio {
  margin-left: 18%;
}
</style>
